import { Analyzer } from './answer_analyzer';
// THIS BELONGS IN SRAM-ANALYSIS. ITS JUST HERE FOR EASY DEV

// /**
// 	Accepts a report description as json and calculatios for reference.
//     Builds necessary object and values for rendering.
// **/
export class ReportBuilder {

    private answerJson: any;
    private reportJson: any;
    private scenarios: any[];
    private calcs: any;  // list of calculations
    private contextLayer: number;
    private report: any;
    private data: any; // data to be looped for report

    // /**
    //  *
    //  * @param answerJson
    //  * @param reportJson
    //  * @param scenarios
    //  */
    constructor(answerJson: any, reportJson: any, scenarios: any[]) {
        this.answerJson = answerJson;
        this.reportJson = reportJson;
        this.scenarios = scenarios;
        this.data = [];
        this.contextLayer = 0;

        // can use reportImpl if we move the models to analysis
        this.report = {description: reportJson,
                       id: reportJson.id,
                       code: reportJson.code,
                       name: reportJson.name,
                       context: reportJson.meta.context,
                       display: reportJson.display,
                       ranges: reportJson.meta.ranges,
                       visible: true,
                       cssclass: reportJson.meta.cssclass,
                       levels: reportJson.meta.levels,
                       rows: []};

        if(reportJson.display.indexOf('Chart') > -1) {
            this.report.display = reportJson.meta.chart_type;
        }
    }

    // /**
    //  *
    //  * @param data
    //  * @param layer
    //  */
    getReport = async (calcs: any, layer?: number): Promise<any> => {
        this.calcs = calcs;
        this.contextLayer = (layer) ? layer : 0;
        await this._pullContextData();
        await this._buildReport();
        return this.report;
    }

    private _pullContextData = async () => {
        if (this.reportJson.meta.context === 'Scenario') {
          this.data = this.scenarios;
        } else if (this.reportJson.meta.context === 'Layer') {
            this.data = [];
            for(let i = 1, l = this.answerJson.number_layers; i < l; i++) {
              this.data[ i - 1 ] = {name: i};
            };
        } else if (this.reportJson.meta.context === 'ScenarioGroup') {
            this.data = [];
            let p: string = null;
            this.scenarios.forEach(s => {
                if (s.parent !== p) {
                    this.data.push(s);
                }
                p = s.parent;
            });
        } else if (this.reportJson.meta.context === 'AssetType') {
            this.data = [];
            Analyzer._traverse(this.answerJson, (key, value, scope) => {
                if (key === 'type' && value === 'AssetType' && scope.Reportable) {
                    if (scope.selected || Analyzer.checkNestedVals(scope, 'selected', true)) {
                        this.data.push(scope);
                    }
                }
            });
        } else if (this.reportJson.meta.context === 'Question') {
            if (this.reportJson.meta.selector === 'Layer') {
                let num = 0;
                this.answerJson.layers.forEach(layer => {
                    Analyzer._traverse(layer.questions, (key, value, scope) => {
                        if (key === 'question_options') {
                            for (let i = 0; i < value.length; i++) {
                                if (value[i].selected) {
                                    if (num !== scope.layer) {
                                        value[i].group = `Layer ${scope.layer}\n`;
                                        num = scope.layer;
                                    }
                                    value[i].parent = `${scope.taxonomy} - ${scope.label}`;
                                    this.data.push(value[i]);
                                }
                            }
                        }
                    });
                });
            } else {
                if (this.reportJson.meta.grouped) {
                    Analyzer._traverse(this.answerJson, (key, value, scope) => {
                        if (key === 'question_options' && scope.taxonomy === this.reportJson.meta.selector) {
                            for (let i = 0; i < value.length; i++) {
                                if (value[i].selected) {
                                    value[i].parent = scope.label;
                                    this.data.push(value[i]);
                                }
                            }
                        }
                    });
                } else {
                    Analyzer._traverse(this.answerJson, (key, value, scope) => {
                        if (key === 'label' && scope.question_options && scope.taxonomy === this.reportJson.meta.selector) {
                            this.data.push(scope);
                        }
                    });
                }
            }
        }

    }

    private _buildReport = async() => {
        if ( this.reportJson.display === 'Table') {
            return this._buildTable();
        } else if (this.reportJson.display === 'Tabs') {
            return this._buildTabs();
        } else if (this.report.display === 'SunburstChart') {
          return this._buildSunburstChart();
        } else if (this.report.display === 'NestedChart') {
          return this._buildNestedChart();
        } else {
            return this._buildChart();
        }
    }

    // tabs are boxes (like panels) with some display data and a list of tabs
    // includes a header, small text, a list of props and tabs
    private _buildTabs = async (): Promise<any> => {
      return new Promise((resolve, reject) => {
        this.data.forEach((d: any) => {
            const row = {props: [], tabs: [], gauges: []};

            this.reportJson.meta.datasets.forEach((dataset: any) => {
              if (dataset.header === 'Props') {
                dataset.datasets.forEach((subD: any) => {
                  row.props.push({name: subD.header, val: this._getVal(subD, d)});
                });
              } else if (dataset.header === 'Gauge') {
                dataset.datasets.forEach((subD: any) => {
                  const g: any = {name: subD.name, value: this._getVal(subD, d), type: subD.type};
                  if (subD.link_subject) {
                    g.link = d.uid;
                  }
                  row.gauges.push(g);
                });
              } else if (dataset.header === 'Tabs') {
                // expand as needed
                if (dataset.context === 'AssetScenario') {
                  this.scenarios.filter(s => (d.scenarios.indexOf(s.nick) > -1) ).forEach((s: any) => {
                    dataset.datasets.forEach((subD: any) => {
                      row.tabs.push(
                        {
                          name: this._getVal(subD, s),
                          hover: this._evalHovers(d, subD.hover, true, s),
                          adjectival: this._evalFormula(d, subD.adjectival, true, s)
                        }
                      );
                    });
                  });
                }
              } else {
                row[dataset.header] = this._getVal(dataset, d);
              }
            });
            this.report.rows.push(row);
        });
        return resolve(this.report);
      });
    }

    private _buildChart = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            this.data.forEach((d: any) => {
                if (this.reportJson.meta.grouped) {
                    const row = {};

                    // filter out datasets
                    const subDatasets = this.reportJson.meta.datasets.filter(ds => ds.datasets)

                    this.reportJson.meta.datasets.forEach((dataset: any) => {
                        if(!dataset.datasets) {
                            row[dataset.header] = this._getVal(dataset, d);
                        }
                    });
                    subDatasets.forEach((dataset: any) => {
                        let r = JSON.parse(JSON.stringify(row));
                        dataset.datasets.forEach(subD => {
                            r[subD.header] = this._getVal(subD, d);
                        });
                        r.realName = r.name;
                        r.name = r.group + '_' + r.name;
                        this.report.rows.push(r);
                    });

                } else {
                    const row = {};
                    this.reportJson.meta.datasets.forEach((dataset: any) => {
                        row[dataset.header] = this._getVal(dataset, d);
                    });
                    this.report.rows.push(row);
                }
            });
            return resolve(this.report);
        });
    }

    private _buildNestedChart = async (): Promise<any> => {
      return new Promise((resolve, reject) => {
        this.data.forEach((d: any) => {
          this.reportJson.meta.datasets.forEach((dataset: any) => {
            this._buildNestedChartRow(dataset, d, this.report.rows);
          });
        });
        return resolve(this.report);
      });
    }

    private _buildNestedChartRow = (dataset: any, data: any, rows: any[], layerCheck:boolean=true) => {
      if (dataset.context && dataset.context === 'Layer' && layerCheck) {
        for ( let i = 1; i <= this.answerJson.number_layers; i++ ) {
          this.contextLayer = i;
          this._buildNestedChartRow(dataset, data, rows, false);
        }
      } else {
        const row: any = {};
        if (dataset.header.match(/LAYER/gi)) {
          row.header = dataset.header.replace(/LAYER/gi, `${this.contextLayer}`);
        } else {
          row.header = dataset.header;
        }
        row.value = this._getVal(dataset, data);
        row.level = dataset.level;
        if (dataset.datasets) {
          row.children = [];
          dataset.datasets.forEach((ds: any) => {
            this._buildNestedChartRow(ds, data, row.children);
          });
        }
        if (dataset.link) {
          if (dataset.ref) {
            row.ref = dataset.ref;
          }
          if (dataset.link.match(/LAYER/gi)) {
            row.link = dataset.link.replace(/LAYER/gi, `${this.contextLayer}`);
          } else {
            row.link = dataset.link;
          }
        }
        if (dataset.type){
          row.type = dataset.type;
        }
        rows.push(row);
      }
    }

    private _buildSunburstChart = async (): Promise<any> => {
      return new Promise((resolve, reject) => {
        this.data.forEach((d: any) => {
          // copy datasets
          const cdata = JSON.parse(JSON.stringify(this.reportJson.meta.datasets));

          // loop through and populate values
          // duplicate layer rows
          Analyzer._traverse(cdata, (key, value, scope) => {
            if (key === 'context' && value === 'Layer') {
              const r = JSON.parse(JSON.stringify(scope.children));
              scope.children = r;
              this.contextLayer = 1;
              // console.log("r", r);
              r.forEach(kid => {
                const copykid = JSON.parse(JSON.stringify(kid));
                if (kid.name.match(/LAYER/gi)) {
                  kid.name = kid.name.replace(/LAYER/gi, `${this.contextLayer}`);
                }
                kid.value = this._evalFormula(d, kid.value);
                kid.c = true;

                for (let i = 2; i < this.answerJson.number_layers; i++) {
                  this.contextLayer = i;
                  const nkid = JSON.parse(JSON.stringify(copykid));
                  kid.children = [nkid];
                  if (nkid.name.match(/LAYER/gi)) {
                    nkid.name = nkid.name.replace(/LAYER/gi, `${this.contextLayer}`);
                  }
                  nkid.value = this._evalFormula(d, nkid.value);
                  nkid.c = true;
                  kid = nkid;
                }
              });
            } else {
              if (key === 'value' && !scope.c) {
                scope[key] = this._evalFormula(d, scope[key]);
                scope.c = true;
              }
            }
          });

          // analyze values & names with layers. I do realized this is very specific

          this.report.rows.push(cdata);
        });

        return resolve(this.report);
      });
    }

    private _getVal = (dataset: any, dataSource: any): any => {
        if (dataset.formula) {
            return this._evalFormula(dataSource, dataset.formula);
        } else if (dataset.value) {
            return dataset.value;
        }
    }


    private _buildTable = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            let pg = '';
            // loop through current data
            this.data.forEach((d: any) => {
                // if grouped and this is the next grouping, add headers
                if(this.reportJson.meta.grouped && pg !== d.parent) {
                    this._buildTableHeaders(d.parent, d.group);
                    pg = d.parent;
                }
                // if not grouped and this is the first iteration, then add headers
                if(!this.reportJson.meta.grouped && pg === '') {
                    this._buildTableHeaders();
                    pg = 'done';

                }
                // loop through datasets and build table rows
                const row = [];
                this.report.rows.push(row);
                this._buildTableRow(d, row);
            });

            return resolve(this.report);
        });
    }

    // this is limited because I am limited.
    private _buildTableHeaders = (groupHdr?: string, group?: string) => {
        const gp = {header: group, cols: 0};
        if (group) {
          this.report.rows.push([gp]);
        }
        const gHdr = {header: groupHdr, cols: 0};
        if (groupHdr) {
            this.report.rows.push([gHdr]);
        }
        const row = [];
        const row2 = [];
        this.report.rows.push(row);
        this.reportJson.meta.datasets.forEach((d: any) => {
            const tr: any = {header: d.header};
            if (d.datasets) {
                // set other cols to span row2
                row.forEach(th => { if (th.cols === 1) {th.rows = 2; }});
                tr.cols = d.datasets.length;
                d.datasets.forEach((d2: any) => {
                    row2.push({header: d2.header});
                });
                gHdr.cols = gHdr.cols + tr.cols;
            } else {
                tr.cols = 1;
                gHdr.cols++;
            }
            row.push(tr);
        });
        gp.cols = gHdr.cols;

        if (row2.length > 0) { this.report.rows.push(row2); };
    }

    private _buildTableRow = (dataScope: any, root: any) => {
        this.reportJson.meta.datasets.forEach((d: any) => {
            if (d.formula) {
              root.push(
                {
                  value: this._evalFormula(dataScope, d.formula, true),
                  hover: this._evalHovers(dataScope, d.hover, true),
                  cssclass: d.cssclass
                }
              );
            } else if (d.value) {
                root.push({value: d.value, hover: this._evalHovers(dataScope, d.hover, true), cssclass: d.cssclass});
            }

            if (d.datasets) {
                // loop through a nested dataset - adding to current row. context determines if it's an additional loop.
                if (d.context) {
                    // additional loop with nested table maybe...
                    // so far the only loop is a Layer
                    // add rowspan to all other tds in row
                    // todo: reduce duplicate code
                    const ml = this.answerJson.number_layers;
                    this.contextLayer = 1;
                    root.forEach(td => { td.rows = ml; } );
                    d.datasets.forEach((dsub: any) => {
                        if (dsub.formula) {
                          root.push(
                            {
                              value: this._evalFormula(dataScope, dsub.formula, true),
                              hover: this._evalHovers(dataScope, dsub.hover, true),
                              cssclass: d.cssclass
                            }
                          );
                        } else if (dsub.value) {
                            root.push({value: dsub.value, hover: this._evalHovers(dataScope, dsub.hover, true), cssclass: d.cssclass});
                        }
                    });
                    this.contextLayer = 2;
                    while (this.contextLayer <= ml) {
                        const newRow = [];
                        d.datasets.forEach((dsub: any) => {
                            if (dsub.formula) {
                              newRow.push(
                                {
                                  value: this._evalFormula(dataScope, dsub.formula, true),
                                  hover: this._evalHovers(dataScope, dsub.hover, true),
                                  cssclass: d.cssclass
                                });
                            } else if (dsub.value) {
                              newRow.push({value: dsub.value, hover: this._evalHovers(dataScope, dsub.hover, true), cssclass: d.cssclass});
                            }
                        });
                        this.report.rows.push(newRow);
                        this.contextLayer++;
                    }
                } else {
                    d.datasets.forEach((dsub: any) => {
                        if (dsub.formula) {
                            root.push(
                              {
                                value: this._evalFormula(dataScope, dsub.formula, true),
                                hover: this._evalHovers(dataScope, dsub.hover, true),
                                cssclass: d.cssclass
                              }
                            );
                        } else if (dsub.value) {
                            root.push({value: dsub.value, hover: this._evalHovers(dataScope, dsub.hover, true), cssclass: d.cssclass});
                        }
                    });
                }
            }

        });
    }

    // /**
    //  * Builds tooltips from the report json.
    //  * @param dataScope
    //  * @param hovers
    //  * @param retVal
    //  * @returns
    //  *
    //  * Example: ['PROP(name)']"
    //  * Example: [["Layer Detection Value (BSE)", "#{detect_net_calc}", "RISK(#{detect_net_calc},[x>=(2/3):H],[(1/3)<=x && x<(2/3):M],[x==0:NONE],[x:L])"],
    //              ["BSE Adjusted for Timeliness", "#{detect_bse_calc}", "RISK(#{detect_bse_calc},[x>=(2/3):H],[(1/3)<=x && x<(2/3):M],[x==0:NONE],[x:L])"]]
    //  */
    private _evalHovers = (dataScope: any, hovers: string[], retVal?: boolean, scopeScenario?: any): any => {
        const hoverVals = [];
        if(hovers === undefined) { return hoverVals; };
        hovers.forEach((hover: any) => {
            if (hover instanceof Array) {
                const hoverRow = [];
                hover.forEach(hov => {
                  hoverRow.push(this._evalFormula(dataScope, hov, retVal, scopeScenario));
                });
                hoverVals.push(hoverRow.join(' '));
            } else {
                hoverVals.push(this._evalFormula(dataScope, hover, retVal, scopeScenario));
            }
        });
        return hoverVals;
    }


    // /**
    //  *
    //  * @param dataScope
    //  * @param formula
    //  * @param retVal - True, return the formula value if not a number. False, return null if not a number
    //  * @returns
    //  */
    private _evalFormula = (dataScope: any, formula: string, retVal?: boolean, scopeScenario?: any): any => {
        try {
            let f = formula;
            if (f.match(/^STAT-/g)) {
                return f.substring(5);
            }
            if (f.match(/MAXLAYER/gi)) {
                f = f.replace(/MAXLAYER/gi, this.answerJson.number_layers);
            }
            if (f.match(/LAYER/gi)) {
                f = f.replace(/LAYER/gi, `${this.contextLayer}`);
            }
            if (f.match(/^CODE\(/)) {
                const code = f.substring(5, f.length - 1);
                // get first nick for this question. This is specifically
                // for TA. Need to revisit this at some point
                let snick = 'T1'; // this default should not apply
                if (dataScope.question_options) {
                  snick = dataScope.question_options[0].scenarios[0];
                } else if (scopeScenario) {
                  snick = scopeScenario.nick;
                }
                return this._findCalcByCode(snick, code, dataScope);
            }
            else if (f === 'SCENARIOS') {
              return this.scenarios.filter(s => (dataScope.scenarios.indexOf(s.nick) > -1) ).map(s => ({value: s.nick, hover: s.name}) );
            }
            else if (f.match(/^PROP\(/)) {
              const prop = f.substring(5, f.length - 1);
              return dataScope[prop];
            }
            else if (f.match(/^MAXPROP\(/)) {
              // MAXPROP('TA1 Consequence Numerical')")
              const prop = f.substring(9, f.length - 2);  // assume prop name in single quotes
              const c = {value: ''};
              Analyzer.calculateMaxProp(prop, c, dataScope).then(res => {
                c.value = res;
              });
              return c.value;
            } else if (f.match(/^MINPROP\(/)) {
              const prop = f.substring(9, f.length - 2);  // assume prop name in single quotes
              const c =  { value: '' };
              Analyzer.calculateMinProp(prop, c, dataScope).then(res => {
                c.value = res;
              });
              return c.value;
            }
            else {
                if (f.match(/calc[_\d]+/gi)) {
                    f = this._findAnswerVals(f, dataScope, dataScope.nick);
                }
                if (f.match(/MAXCALC/gi)) {
                  const matches = f.match(/MAXCALC\([a-z_ ]+\)/i); // only chance for one
                  const code = matches[0].substring(8, matches[0].length - 1);
                  const val = this._findMaxVal(code, dataScope);
                  f = f.replace(/MAXCALC\([a-z_ ]+\)/i, val);
                }
                const v = Analyzer.calculateFormula(f, retVal);
                return ((typeof(v) === 'number') && (v % 1 !== 0)) ? Number(v.toFixed(4)) : v;
            }
        } catch (err) {

        }
    }

    // this one is fun.
    // if it's an asset type or question, you want the max value for the referenced calculation for
    //      this asset type across all scenarios.
    // if it's a scenario, then you want the max value for all the references calculations within the scenario.
    private _findMaxVal(calcCode: string, entity: any): any {
        const vals: number[] = [];
        if (entity.type === 'Scenario') {
            // get all "R" calculations
            Analyzer._traverse(this.answerJson, (key, value, scope) => {
                if (key === 'code' && value === calcCode) {
                    const c = this.calcs[entity.nick][scope.uid];
                    if (c && c !== 'N/A' && c !== 'NAN') {
                        vals.push(c);
                    }
                }
            });
        } else {
            // get r calculation for the asset type
            const refcalc = entity.calculations.find(c => c.code === calcCode);
            if (refcalc.uid) {
                for (const nick in this.calcs) {
                    const c = this.calcs[nick][refcalc.uid];
                    if (c && c !== 'N/A' && c !== 'NAN') {
                        vals.push(c);
                    }
                }
            }

        }

        if (vals.length > 0) {
            const f = 'Math.max(' + vals.join(',') + ')';
            return eval(f);
        } else {
            return 0;
        }
    }
    // finds calculation in question
    private _findCalcByCode(snick: string, calcCode: string, entity: any): any {
        let val = null;

        // get the calc value.
        // snick is passed as we only want to retrieve one value.
        // a bit of a hack
        const refcalc = entity.calculations.find(c => c.code === calcCode);
        // value can be found in calcs list
        if (refcalc.uid) {
          val = this.calcs[snick][refcalc.uid];
        }

        return val;
    }


    private _findAnswerVals(f: string, entity: any, nick: string): string {
        const uids = f.match(/calc[_\d]+/gi);
        if (uids) {
            uids.forEach((uid: any) => {
                const val = this.calcs[nick][uid];
                if (val && val !== 'N/A' && val !== 'NAN') {
                    f = f.replace(`{${uid}}`, val);
                } else {
                    f = f.replace(`{${uid}}`, '0');
                }
            });
        }

        return f;
    }

}
