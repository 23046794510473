import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RuleService {
  constructor(public api: Api) {

  }

  baseUrl = 'user_rules_agreements/';

  add(agreementValues: any, email: any): Observable<any> {
    const params = {
      email, is_admin: agreementValues.isAdmin,
      read_rules: agreementValues.readRules,
      read_signed_privacy: agreementValues.agreeRules};
    return this.api.post(this.baseUrl, {user_agreement:  params} );
  }

  getAll(): Observable<any> {
    return this.api.get(this.baseUrl);
  }

  checkAgreement(email: any): Observable<any> {
    return this.api.get(this.baseUrl + 'check_agreement/', {email});
  }


}

