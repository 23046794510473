import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { Task, TaskType } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TaskService {
  private assetIsRecoverableBS = new BehaviorSubject<any>(null);
  assetIsRecoverable = this.assetIsRecoverableBS.asObservable();

  constructor(private api: Api) { }

  getTaskTypes(): Observable<TaskType[]> {
    return this.api.get('task_types');
  }

  saveTask(task: Task): Observable<Task> {
    return this.api.post('tasks',  {task});
  }

  updateTask(task: Task): Observable<Task> {
    return this.api.put('tasks/' + task.id,  {task});
  }

  deleteTask(task: Task): Observable<Task> {
    return this.api.delete('tasks/' + task.id);
  }

  getQuestionSurveyAnswerTasks(question: any, surveyAnswerId: any): Observable<any> {
    return this.api.get('question_survey_answer_tasks/', {question_id: question.id, survey_answer_id: surveyAnswerId});
  }

  getTasks(): Observable<Task[]> {
    return this.api.get('tasks');
  }

  getQuestionSurveyAnswerTasksByTaskTypeID(question: any, surveyAnswerId: any, taskTypeId: any): Observable<any> {
    const params = {question_id: question.id, survey_answer_id: surveyAnswerId, task_type_id: taskTypeId}
    const items = this.api.get('question_survey_answer_tasks/get_question_survey_answer_tasks_by_type/', params);
    return items;
  }

  addQuestionSurveyAnswerTask(question: any, surveyAnswerId: any, task: Task, duration: number): Observable<any> {
    const params = {question_id: question.id, survey_answer_id: surveyAnswerId, task_id: task.id, duration: duration};
    return this.api.post('question_survey_answer_tasks', params);
  }

  updateQuestionSurveyAnswerTask(params: any, questionTaskId: number): Observable<any> {
    return this.api.put('question_survey_answer_tasks/' + questionTaskId, params);
  }

  deleteQuestionSurveyAnswerTask(questionTask: any): Observable<any> {
    return this.api.delete('question_survey_answer_tasks/' + questionTask.id );
  }

  addQuestionTimelineIcon(params: any): Observable<any> {
    return this.api.post('question_timeline_icons/', {question_timeline_icon: params});
  }

  deleteQuestionTimelineIcon(questionId: any): Observable<any>{
    return this.api.delete('question_timeline_icons/delete_all', {question_id: questionId});
  }

  updateQuestionTimelineIcon(questionTimelineIcon: any, params: any): Observable<any> {
    return this.api.put('question_timeline_icons/' + questionTimelineIcon.id, {question_timeline_icon: params});
  }

  getQuestionSurveyAnswer(questionId: any, surveyAnswerId: any): Observable<any> {
    return this.api.get('question_timeline_icons/question_survey_answer/', {question_id: questionId, survey_answer_id: surveyAnswerId});
  }

  deselectAllQuestionOption( surveyAnswerId: any, questionId: any,  questionOptionIds: any): Observable<any>{
    return this.api.put('survey_answers/question_options_deselect_all/' + surveyAnswerId,
      {question_option_ids: questionOptionIds, question_id: questionId});
  }

  saveAllQuestionSurveyAnswer(questionSurveyAnswerTasks: any): Observable<any>{
    return this.api.post('question_survey_answer_tasks/save_all_tasks', {question_survey_answer_tasks: questionSurveyAnswerTasks});
  }

}
